export const msalConfig = {
    auth: {
        clientId: "707d9835-bfef-4248-b2fd-f6ebaab2389e",
        authority:
            "https://login.microsoftonline.com/86389440-a0bc-4f71-8a3d-5e0dea9c3eaa", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: window.REACT_APP_UI_BASE_URL,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"],
};
