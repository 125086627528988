import React, { ReactElement } from "react";
import Button from "./Button.tsx";
import _ from "lodash";
import "./ModuleList.scss";
import Switch from "./Switch.tsx";
import { default as Trash } from "./img/trash-solid.svg";
import { default as Angle } from "./img/angle-down-solid.svg";
import { default as Pencil } from "./img/pencil-solid.svg";
import { default as Plus } from "./img/plus-solid.svg";
import { useState } from "react";

export const ModuleList = (props) => {
    const {
        modules,
        addTableRow,
        updateVisableModules,
        editModule,
        resetModule,
        editOff,
    } = props;

    const [active, setActive] = useState(null);

    const toggle = (index) => {
        if (active === index) {
            return setActive(null);
        }
        setActive(index);
    };

    const viewModule = (moduleId) => {
        document.getElementById(moduleId + "DIV").scrollIntoView();
    };

    return (
        <div className="accordion">
            {Object.keys(modules).map((moduleId, index) => (
                <div key={moduleId + "Item"} className="moduleItem">
                    <div
                        className="moduleItemHeader"
                        onClick={() => {
                            toggle(index);
                            viewModule(moduleId);
                        }}
                    >
                        <label>{_.startCase(modules[moduleId].name)}</label>
                        <span>
                            <p id={moduleId + "p"}></p>
                            <Switch
                                type="checkbox"
                                id={modules[moduleId]}
                                defaultChecked={modules[moduleId].default}
                                onChange={() => updateVisableModules(moduleId)}
                            />
                            <img
                                className={
                                    active === index ? "angle inverse" : "angle"
                                }
                                src={Angle}
                                width="12px"
                                height="12px"
                                alt="Open"
                            />
                        </span>
                    </div>
                    <div
                        className={
                            active === index
                                ? "moduleItemContent show"
                                : "moduleItemContent"
                        }
                    >
                        <Button
                            buttonStyle="btn--danger"
                            onClick={() => {
                                resetModule(moduleId);
                                editOff(moduleId);
                            }}
                        >
                            <img
                                className="trash"
                                src={Trash}
                                width="12px"
                                height="12px"
                                alt=""
                            />
                            Reset
                        </Button>
                        {modules[moduleId].current_html.includes(
                            moduleId + "TABLE"
                        ) ? (
                            <Button
                                buttonStyle="btn--secondary"
                                onClick={() => {
                                    addTableRow(moduleId);
                                }}
                            >
                                <img
                                    src={Plus}
                                    width="12px"
                                    height="12px"
                                    alt=""
                                />
                                Row
                            </Button>
                        ) : (
                            ""
                        )}
                        <Button
                            buttonStyle="btn--secondary"
                            onClick={() => {
                                editModule(moduleId);
                            }}
                        >
                            <img
                                src={Pencil}
                                width="12px"
                                height="12px"
                                alt=""
                            />
                            Edit
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    );
};
