/* eslint-disable no-useless-escape */
import { SHA256, HmacSHA256 } from "crypto-js";
import { Buffer } from "buffer/";

/**
 * Changes the visibility of module divs
 * @param  {[string]} css The css to inject into the DOM
 * @return {[void]}
 */
function applyPreviewStyles(css) {
    const style = document.createElement("style");
    style.textContent = css;
    document.head.appendChild(style);
}

/**
 * Populates the ui with the information of the requested document
 * @param  {[string]} instance_id The id of the document instance
 * @param  {[function]} initLoad The function to init the MainApp load
 * @return {[void]}
 */
export async function populateUi(instance_id, initLoad) {
    var data_for_hmac = SHA256(Math.random()).toString();
    var body_data = JSON.stringify({ data: data_for_hmac });

    const HMAC_KEY = window.REACT_APP_HMAC_KEY;
    var hmac_token = HmacSHA256(body_data, HMAC_KEY).toString();

    const GET_DTATA_URL =
        window.REACT_APP_RETRIEVAL_ENDPOINT +
        "id=" +
        instance_id +
        "&HMAC=" +
        hmac_token;

    // making sure the fetch can connect to the API
    try {
        var response = await fetch(GET_DTATA_URL, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: body_data,
        });
    } catch (error) {
        throw "Could not connect to server"; // dreaded error
    }

    // error handling
    if (!response.ok) {
        throw "Unable to find document.";
    }

    // applying result to the MainApp
    var result = await response.json();
    var modules = result.modules;
    var editable_modules = [];

    // allowing for divs to be editable
    for (let module in modules) {
        editable_modules[module] = { editable: false };

        // making the tables have unique ids to reference when adding rows
        if (modules[module].base_html.includes("MODULETABLE")) {
            var new_html = modules[module].base_html.replace(
                "MODULETABLE",
                module + "TABLE"
            );
            modules[module].base_html = new_html;
            modules[module].current_html = new_html;
        }
    }

    applyPreviewStyles(result.css);
    initLoad(modules, editable_modules, result.type);

    return;
}

export async function saveInstanceModules(raw_body_data, instance_id) {
    var body_data = JSON.stringify(raw_body_data);
    body_data = Buffer.from(body_data).toString("base64");
    body_data = JSON.stringify({body_data: body_data});

    const HMAC_KEY = window.REACT_APP_HMAC_KEY;
    var hmac_key = HmacSHA256(body_data, HMAC_KEY);

    const SAVE_URL =
        window.REACT_APP_UPDATE_ENDPOINT +
        "id=" +
        instance_id +
        "&HMAC=" +
        hmac_key;

    return fetch(SAVE_URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: body_data,
    });
}

export function findVariables(html) {
    const regexp = /<span class=['"]VARIABLE_([^<>]*)['"]/g;
    const matches = [...html.matchAll(regexp)];

    var twig_vars = new Set();
    for (let match in matches) {
        twig_vars.add(matches[match][1]);
    }
    return Array.from(twig_vars);
}
