import React from "react";
import "./Button.scss";

interface IButtonProps {
    children?: React.ReactNode;
    onClick?: () => void;
    buttonStyle: string;
    id?: string;
}

const defaultProps: IButtonProps = {
    buttonStyle: "btn--primary",
};

const Button = (props: IButtonProps) => {
    const { children, onClick, buttonStyle, id } = props;

    return (
        <button className={`btn ${buttonStyle}`} onClick={onClick} id={id}>
            {children}
        </button>
    );
};

Button.defaultProps = defaultProps;

export default Button;
