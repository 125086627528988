import { Buffer } from "buffer/";
import _ from "lodash";
import { HmacSHA256 } from "crypto-js";
import { toast } from "react-toastify";

/**
 * Changes the visibility of module divs
 * @param  {[Buffer]} encoded_buffer The pdf buffer
 * @return {[void]}
 */
function downloadPdf(encoded_buffer, doc_type) {
    const pdf_buffer = Buffer.from(encoded_buffer, "base64");
    const file = new Blob([pdf_buffer], { type: "application/pdf" });
    var fileURL = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.href = fileURL;
    link.download = doc_type + ".pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * Changes the visibility of module divs
 * @param  {[string]} html_content The html to send to the backend
 * @return {[void]}
 */
function sendHtml(html_content, doc_type, instance_id) {
    const formatted_doc_type = _.kebabCase(doc_type);
    var html = Buffer.from(html_content).toString("base64");
    var body_data = JSON.stringify({ html: html });

    const HMAC_KEY = window.REACT_APP_HMAC_KEY;
    var hmac_token = HmacSHA256(body_data, HMAC_KEY).toString();

    if (!html_content) {
        alert("There is nothing in the document. Please select a module.");
        return;
    }

    var UPDATE_URL =
        window.REACT_APP_EXPORTING_ENDPOINT +
        "doc-type=" +
        formatted_doc_type +
        "&id=" +
        instance_id +
        "&HMAC=" +
        hmac_token;

    fetch(UPDATE_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: body_data,
    }).then((response) => {
        if (!response.ok) {
            toast.error('Error generating PDF.');
            return;
        }

        response.text().then((result) => downloadPdf(result, doc_type));
        toast.success('Document Generated!');
    });
}

export default sendHtml;
