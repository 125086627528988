import React, { ReactElement } from "react";
import Button from "./Button.tsx";
import "./Modal.scss";
import ReactDom from "react-dom";
import { toast } from "react-toastify";
import _ from "lodash";
interface ModalProps {
    open: boolean;
    onClose: () => void;
    saveVariables: () => void;
    var_names: [];
}

export function Modal(props: ModalProps) {
    const { open, onClose, saveVariables, var_names } = props;
    if (!open) return null;
    var varElements = (var_names) => {
        if (!var_names || var_names.length == 0) {
            toast.warn("This document doesn't contain deal specific data.");
            onClose();
            return;
        }

        var rows = new Array<ReactElement>();
        for (let name of var_names) {
            var element_class = `VARIABLE_${name}`;

            try {
                var current_value =
                    document.getElementsByClassName(element_class)[0]
                        .textContent;
            } catch (e) {
                // toast.error(
                //     `Variable ${name} has been removed from the document.`
                // ); //this should work but there is something w the regex that needs fixing
                continue;
            }

            rows.push(
                <tr>
                    <td>{_.startCase(name)}:</td>
                    <td>
                        <input
                            id={name + "_NEW_VALUE"}
                            type="text"
                            defaultValue={current_value}
                        />
                    </td>
                </tr>
            );
        }
        return rows;
    };

    return ReactDom.createPortal(
        <>
            <div
                className="background"
                onClick={() => {
                    saveVariables(var_names);
                    onClose();
                    toast.success("Variables updated.");
                }}
            ></div>
            <div className="modal">
                <h2>Edit Incoming Data</h2>
                <table className="variables">{varElements(var_names)}</table>
                <div className="actions">
                    <Button
                        buttonStyle="btn--secondary"
                        onClick={() => {
                            onClose();
                            toast.warn("No changes were made.");
                        }}
                    >
                        Discard
                    </Button>
                    <Button
                        buttonStyle="btn--primary"
                        onClick={() => {
                            saveVariables();
                            onClose();
                            toast.success("Variables updated.");
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </>,
        document.body
    );
}
