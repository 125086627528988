import React from "react";
import "./Header.scss";
import "./styles.scss";

export const Header = ({ title }) => {
    return (
        <div className="header">
            <h1>{title}</h1>
        </div>
    );
};
