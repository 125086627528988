import React from "react";
import "./Sidebar.scss";
import { default as logo } from "./img/think-logo.svg";

export const Sidebar = ({ children }) => {
    return (
        <div className="sidebar">
            <div className="logo">
                <img src={logo} width="208px" height="66px" alt="Think Logo" />
            </div>
            <div className="sidebar-content">{children}</div>
        </div>
    );
};
