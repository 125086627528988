import React from "react";
import "./Switch.scss";

interface ISwitchProps {
    onChange: () => void;
    id?: string;
    defaultChecked?: boolean;
}

const Switch = (props: ISwitchProps) => {
    const { onChange, id, defaultChecked } = props;
    return (
        <label className="switch">
            <input
                type="checkbox"
                id={id}
                defaultChecked={defaultChecked}
                onChange={onChange}
            />
            <span className="slider"></span>
        </label>
    );
};

export default Switch;
