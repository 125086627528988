import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// auth imports
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { Provider, ErrorBoundary } from '@rollbar/react'; // <-- Provider imports 'rollbar' for us

const msalInstance = new PublicClientApplication(msalConfig);
const currentEnv = window.REACT_APP_ENVIRONMENT;

// no rollbar on dev environment
var app;
if (currentEnv == 'production' || currentEnv == 'staging') {
    const rollbarConfig = {
      accessToken: window.REACT_APP_ROLLBAR_CLIENT_TOKEN,
      environment: currentEnv,
    };

    app =
        <Provider config = {rollbarConfig}>
            <ErrorBoundary>
                <MsalProvider instance={msalInstance}>
                    <App />
                </MsalProvider>
            </ErrorBoundary>
        </Provider>;
} else {
    app =
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(app);
