import React, { ReactElement } from "react";
import "./PreviewPane.scss";

/**
 * The div used to render a module
 * @param  {[ContainerProps]} props The properties of the component
 * @return {[React.FunctionComponent]}
 */
function DynamicDiv(props) {
    return (
        // using blur because onChange or onInput would not fire correctly
        <div
            id={props.id}
            contentEditable={props.editable}
            onBlur={() => props.onInput(props.id, props.module)}
            dangerouslySetInnerHTML={{ __html: props.html }}
            onClick={() => props.editModule(props.module)}
        />
    );
}

/**
 * Override the default paste behavior to make pasting from outside sources smoother
 * If rich-paste text is found, then clear newlines and paste as raw text
 */
function customPaste(e) {
    let pasteHTML = e.clipboardData.getData("text/html");
    let pasteText = e.clipboardData.getData("text");

    //check to see if it is a panel
    if (pasteHTML.includes("rich-paste")) return true;
    //if its not a panel just paste as text, otherwise paste as html
    else {
        e.preventDefault();

        pasteText = pasteText.replace(/(\r\n|\n|\r)/gm, " ");

        let selection = document.getSelection();
        let txt = document.createTextNode(pasteText);
        if (!selection?.rangeCount) return false;
        selection?.deleteFromDocument();
        selection?.getRangeAt(0).insertNode(txt);
    }
}

/**
 * The collection of module divs
 * @param  {[ContainerProps]} props The properties of the component
 * @return {[React.FunctionComponent]}
 */
export default function PreviewPane(props) {
    var modules = props.modules;
    var edit_states = props.editStates;
    var module_view_arr = new Array<ReactElement>();

    for (let current_module in modules) {
        if (!modules[current_module].default) {
            continue;
        }
        let content = (
            <DynamicDiv
                id={current_module + "DIV"}
                html={modules[current_module].current_html}
                onInput={props.updateCurrentModuleStates}
                module={current_module}
                editable={edit_states[current_module].editable}
                editModule={props.editModule}
                editOn={props.editOn}
            />
        );
        module_view_arr.push(content);
    }

    return (
        <div className="preview">
            <div id="preview_wrapper" onPaste={customPaste}>
                {module_view_arr}
                <br />
            </div>
        </div>
    );
}
