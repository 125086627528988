import React from "react";
// import { createClient } from "redis";
import "./App.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainApp from "./MainApp";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
    });
}

export const App = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    return <>{isAuthenticated ? <MainApp /> : handleLogin(instance)}</>;
};

export default App;
