import React from "react";
import Button from "./Button.tsx";
import "./Commandbar.scss";
import { useState } from "react";
import { Modal } from "./Modal.tsx";

interface ICommandbarProps {
    exportFn: () => void;
    saveFn: () => void;
    saveVariables: () => void;
    twig_vars: [];
}

export const Commandbar = (props: ICommandbarProps) => {
    const { exportFn, saveFn, saveVariables, twig_vars } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className="commandbar">
                <div>
                    <Button buttonStyle="btn--secondary" onClick={saveFn}>
                        Save Draft
                    </Button>
                    <Button
                        buttonStyle="btn--secondary"
                        onClick={() => setIsOpen(true)}
                    >
                        Edit Deal Data
                    </Button>
                </div>
                <div>
                    <Button onClick={exportFn}>Export as Pdf</Button>
                </div>
            </div>
            <div className="Modal">
                <Modal
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    saveVariables={saveVariables}
                    var_names={twig_vars}
                />
            </div>
        </>
    );
};
