import React from "react";
// import { createClient } from "redis";
import GeneratePdf from "./generatePdf";
import "./MainApp.scss";
import PreviewPane from "./components/PreviewPane.tsx";
import { Commandbar } from "./components/Commandbar.tsx";
import { Header } from "./components/Header.tsx";
import { Sidebar } from "./components/Sidebar.tsx";
import { ModuleList } from "./components/ModuleList.tsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { populateUi, saveInstanceModules, findVariables } from "./utils";
import { HmacSHA256 } from "crypto-js";

class MainApp extends React.Component {
    // Constructor
    constructor(props) {
        super(props);
        // look into using hooks and changing App to be a functional component
        this.initLoad = this.initLoad.bind(this);
        this.updateVisableModules = this.updateVisableModules.bind(this);
        this.updateCurrentModuleStates =
            this.updateCurrentModuleStates.bind(this);
        this.editModule = this.editModule.bind(this);
        this.resetModule = this.resetModule.bind(this);
        this.addTableRow = this.addTableRow.bind(this);
        this.saveVariables = this.saveVariables.bind(this);
        this.state = {
            name: null,
            is_loaded: false,
            is_init: false,
            preview_src: "",
            current_module_states: [],
            current_editable_modules_states: [],
        };
        var twig_vars = [];
        var instance_id;
        var doc_type;
    }

    /**
     * Selects a modules
     * @param  {[string]} module The id of the module
     * @return {[void]}
     */
    selectModule(module) {
        document.getElementById(module + "DIV").focus();
    }

    /**
     * Enables editing in a module's preview div
     * @param  {[string]} module The id of the module
     * @return {[void]}
     */
    editModule(module) {
        if (!document.getElementById(module + "DIV")) {
            return;
        }
        // copying the current modules state to edit, then reapply
        var updated_editable_modules_states =
            this.state.current_editable_modules_states;

        updated_editable_modules_states[module].editable = true;
        this.setState({
            current_editable_modules_states: updated_editable_modules_states,
        });

        document.getElementById(module + "p").innerHTML = "(edited)";
    }

    editOff = (moduleId) => {
        document.getElementById(moduleId + "p").innerHTML = "";
    };

    /**
     * Resets a module to its original state
     * @param  {[string]} module The id of the module
     * @return {[void]}
     */
    resetModule(module) {
        var updated_module_states = this.state.current_module_states;
        updated_module_states[module].current_html =
            updated_module_states[module].base_html;
        var html = this.generatePreviewData(updated_module_states);
        this.setState({
            preview_src: html,
            current_module_states: updated_module_states,
        });
    }

    /**
     * Inserts a row into a module that contains a table
     * @param  {[string]} module The id of the module
     * @return {[void]}
     */
    addTableRow(module) {
        if (!document.getElementById(module + "DIV")) {
            return;
        }

        var updated_module_states = this.state.current_module_states;
        var table = document.getElementById(module + "TABLE");
        var num_cells = table.rows[0].cells.length;
        table.insertRow();

        for (let i = 0; i < num_cells; i++) {
            let cell = table.rows[table.rows.length - 1].insertCell(i);
            cell.innerHTML = "EMPTY";
        }

        updated_module_states[module].current_html = document.getElementById(
            module + "DIV"
        ).innerHTML;
        var html = this.generatePreviewData(updated_module_states);

        this.setState({
            preview_src: html,
            current_module_states: updated_module_states,
        });
        // without a timeout, the module isn't selected correctly
        setTimeout(() => this.editModule(module), 0);
    }

    /**
     * Updates the current states of all modules
     * @param  {[array]} module_states (optional) the modified array of module states
     * @return {[string]} The compiled HTMl
     */
    generatePreviewData(modules_states = this.state.current_module_states) {
        var src_html = "";
        for (let module in modules_states) {
            if (!modules_states[module].default) {
                continue;
            }

            src_html += modules_states[module].current_html;
        }
        return src_html;
    }

    /**
     * Changes the visibility of module divs
     * @param  {[string]} module The id of the module
     * @return {[void]}
     */
    updateVisableModules(module) {
        var updated_module_states = this.state.current_module_states;
        updated_module_states[module].default =
            !updated_module_states[module].default;
        var html = this.generatePreviewData(updated_module_states);
        this.setState({
            preview_src: html,
            current_module_states: updated_module_states,
        });
    }

    /**
     * Updates the state of the modules and the preview source
     * @param  {[string]} div_id The id of the div
     * @param  {[string]} module The id of the module
     * @return {[void]}
     */
    updateCurrentModuleStates(div_id, module) {
        var module_div = document.getElementById(div_id);
        var new_module_data = module_div.innerHTML;
        var updated_module_states = this.state.current_module_states;
        var updated_editable_modules_states =
            this.state.current_editable_modules_states;

        updated_module_states[module].current_html = new_module_data;
        updated_editable_modules_states[module].editable = false;
        var html = this.generatePreviewData(updated_module_states);
        this.setState({
            preview_src: html,
            current_module_states: updated_module_states,
        });
    }

    /**
     * Finds and replaces all instances of a variable with the new values
     * @param  {[array]} var_names The names of the variables
     * @return {[void]}
     */
    saveVariables() {
        var var_names = this.twig_vars;
        // creating the json mapping new values to vars. this could be done in the modal and passed as a parm
        var associated_data = {};
        for (let name of var_names) {
            try {
                var new_data_element = document.getElementById(
                    name + "_NEW_VALUE"
                );
                var new_data = new_data_element.value;
            } catch (e) {
                continue;
            }

            // preventing script of html injection
            associated_data[name] = new_data
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;");
        }

        var updated_modules_states = this.state.current_module_states;

        // take a look at regex to make sure that it is consistent
        for (let module in updated_modules_states) {
            var current_html = updated_modules_states[module].current_html;
            var regexp = /<span\sclass=['"]VARIABLE_([^<>]*)['"]\s[^><]*>/g;
            var matches = [...current_html.matchAll(regexp)];
            for (let match in matches) {
                let var_name = matches[match][1];
                let re = new RegExp(
                    `<span\\sclass=['"]VARIABLE_${var_name}['"]\\s[^><]*>[^<>]*<\/span>`,
                    "gm"
                );

                let new_variable = `<span class="VARIABLE_${var_name}" contentEditable=false>${associated_data[var_name]}</span>`;

                current_html = current_html.replace(re, new_variable);
            }
            updated_modules_states[module].current_html = current_html;
        }
        var html = this.generatePreviewData(updated_modules_states);
        this.setState({
            preview_src: html,
            current_module_states: updated_modules_states,
        });
    }

    /**
     * initializes the states on the first load
     * @param  {[array]} modules_states The states of the modules to set
     * @return {[void]}
     */
    initLoad(modules_states, editable_modules, doc_type) {
        // makes sure that the fetch request is complete before running
        if (this.state.is_init || modules_states == []) {
            return;
        }

        let html_src = this.generatePreviewData(modules_states);
        let found_vars = findVariables(html_src);

        this.doc_type = doc_type;
        this.twig_vars = found_vars;

        this.setState({
            is_init: true,
            is_loaded: true,
            preview_src: html_src,
            current_module_states: modules_states,
            current_editable_modules_states: editable_modules,
        });
    }

    /**
     * Updates the exisiting doc reference in the db
     * @return {[void]}
     */
    updateDoc() {
        var body_data = this.state.current_module_states;

        if (!this.state.preview_src) {
            toast.error("There is nothing to save.");
            return;
        }
        saveInstanceModules(body_data, this.instance_id).then((response) => {
                switch (response.ok) {
                    case true:
                        toast.success('Document Saved!');
                        break;
                    case false:
                    default:
                        toast.error('Something went wrong.');
                        break;
                }
            }
        );
        return;
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        // determining the type of doc to generate
        const search = window.location.search;
        var params = new URLSearchParams(search);
        this.instance_id = params.get("id");

        if (!this.instance_id) {
            toast.error("No document id provided.");
            return;
        }

        populateUi(this.instance_id, this.initLoad).catch((error) =>
            toast.error(error)
        );
    }

    /**
     * @inheritdoc
     */
    render() {
        return (
            <div className="App">
                <div className="Sidebar">
                    <Sidebar>
                        <ModuleList
                            modules={this.state.current_module_states}
                            addTableRow={this.addTableRow}
                            updateVisableModules={this.updateVisableModules}
                            editModule={this.editModule}
                            resetModule={this.resetModule}
                            editOff={this.editOff}
                        />
                    </Sidebar>
                </div>
                <div className="Content">
                    <Header title={_.startCase(this.doc_type)} />
                    <Commandbar
                        exportFn={() =>
                            GeneratePdf(
                                this.state.preview_src,
                                this.doc_type,
                                this.instance_id
                            )
                        }
                        saveFn={() => this.updateDoc()}
                        twig_vars={this.twig_vars}
                        saveVariables={this.saveVariables}
                    />
                    <PreviewPane
                        modules={this.state.current_module_states}
                        editStates={this.state.current_editable_modules_states}
                        updateCurrentModuleStates={
                            this.updateCurrentModuleStates
                        }
                        editModule={this.editModule}
                    />
                </div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    draggable
                    pauseOnHover={false}
                />
            </div>
        );
    }
}

export default MainApp;
